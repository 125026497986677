<template>
  <div>
    <CModal title="Agregar Venta" size="lg" :show="isVisibleModalDetail">
      <CForm novalidate>
        <CCardBody>

          <CRow>
            <CCol md="12">
              <CInput
                label="Producto"
                v-model="detail.product.name"
                disabled
                required
                was-validated
              >
                <template #append>
                  <CButton @click="openModalDetail" color="primary">Seleccionar</CButton>
                </template>
              </CInput>
              <CInput type="hidden" :value.sync="detail.product.id" />
            </CCol>
          </CRow>

          <CRow>
            <CCol md="4">
              <CInput
                v-model="detail.product.stock"
                disabled
                label="Stock"
                required
                was-validated
              />
            </CCol>
            <CCol md="4">
              <CInput
                v-model="detail.product.um"
                disabled
                label="Unidad de Medida"
                required
                was-validated
              />
            </CCol>
            <CCol md="4">
              <CInput
                v-model="detail.price"
                @keydown="preventInvalidDecimal($event)"
                label="Precio de venta"
                required
                was-validated
              />
            </CCol>
          </CRow>

          <CRow>
            <CCol md="4">
              <CInput
                v-model="detail.equivalent"
                label="Equivalente"
                @input="updateFromEquivalent"
                @keydown="preventInvalidDecimal($event)"
                required
              />
            </CCol>
            <CCol md="4">
              <CInput
                v-model="detail.amount_saco"
                @input="updateFromSaco"
                @keydown="preventInvalidDecimal($event)"
                label="SACO/UND"
              />
            </CCol>
            <CCol md="4">
              <CInput
                v-model="detail.amount_kg"
                @input="updateFromKg"
                @keydown="preventInvalidDecimal($event)"
                label="KG/UND"
              />
            </CCol>
          </CRow>

        </CCardBody>
      </CForm>

      <template #footer>
        <div v-if="!loadingDetail">
          <CButton color="primary" @click="saveDetail" class="mr-1 mb-3">
            <CIcon name="cil-save" /> Agregar Detalle
          </CButton>
        </div>
        <div v-else>
          <CCol xl="3" lg="4" md="6">
            <CCardBody>
              <div class="sk-chase">
                <div class="sk-chase-dot"></div>
                <div class="sk-chase-dot"></div>
                <div class="sk-chase-dot"></div>
                <div class="sk-chase-dot"></div>
                <div class="sk-chase-dot"></div>
                <div class="sk-chase-dot"></div>
              </div>
            </CCardBody>
          </CCol>
        </div>
      </template>

      <template #header>
        <CButtonClose @click="closeModalDetail" class="text-black"/>
      </template>

    </CModal>

    <!-- MODAL: LISTADO DE PRODUCTOS -->
    <CTableProducts
        :details="details"
        :isVisible="flagModalProducts"
        @select-product="selectProduct"
        @close-modal-products="closeModalProducts"
    />

  </div>
</template>
  
<script>

  import CTableProducts from "../../modals/ModalProducts.vue";
  import Swal from "sweetalert2";

  export default {
    name: "ModalDetail",
    components: {
        CTableProducts,
    },
    props: {
      isVisibleModalDetail: {
        type: Boolean,
        required: true,
      },
      details: {
          type: Array,
      },
    },
    data() {
      return {
        detail: {
          id: "",
          product: {
            "id"                : "",
            "name"              : "",
            "code"              : "",
            "process"           : "",
            "id_unit_measure"   : 0,
            "slug"              : "",
            "um"                : "",
            "stock"             : "",
            "minimum_quantity"  : "",
            "equivalent"        : "",
            "converted_price"   : "",
          },
          equivalent: "",
          amount: "",
          amount_kg: "",
          amount_saco : "",
          price : "",
          total: 0,
        },
        loadingDetail: false,
        flagModalProducts: false,
        loadingButtonsActions: true,
        loadingUnitsMeasure: false,
        loadingAmount: false,
      };
    },
    watch: {
      isVisibleModalDetail(newValue) {
        if (newValue) {
          this.cleanModal();
        }
      },
    },
    methods: {
      openModalDetail() {
        this.flagModalProducts = true;
      },
      saveDetail(){

        let message = "";

        if(this.detail.product.id == ""){
          Swal.fire("Alerta", "Seleccione un producto", "warning");
          return
        } else if(this.detail.price == ""){
          Swal.fire("Alerta", "Ingrese un precio", "warning");
          return
        } else if(this.detail.price <= 0){
          Swal.fire("Alerta", "El precio debe ser mayor a 0", "warning");
          return
        } else if(this.detail.equivalent == ""){
          Swal.fire("Alerta", "Ingrese un equivalente", "warning");
          return
        } 

        //? Se compara con la de cantidad en kilos
        if(this.detail.product.slug == "kg"){
          if(this.detail.amount_saco == ""){
            Swal.fire("Alerta", "Ingrese una cantidad (SACO/UND)", "warning");
            return
          } else if(this.detail.amount_saco <= 0){
            Swal.fire("Alerta", "La cantidad (SACO/UND) debe ser mayor a 0", "warning");
            return
          } else if(this.detail.amount_saco > parseFloat(this.detail.product.stock)){
            Swal.fire("Alerta", "No hay stock sufiente el producto '"+this.detail.product.name+"' cuenta con una cantidad de "+this.detail.product.stock+" "+this.detail.product.um, "warning");
            return
          } else if((parseFloat(this.detail.product.stock) - this.detail.amount_saco < this.detail.product.minimum_quantity)){
            message = ", el producto '"+this.detail.product.name+"' se está agotando";
          }
        } else {
          if(this.detail.amount_saco == ""){
            Swal.fire("Alerta", "Ingrese una cantidad (KG)", "warning");
            return
          } else if(this.detail.amount_saco <= 0){
            Swal.fire("Alerta", "La cantidad (KG) debe ser mayor a 0", "warning");
            return
          } else if(this.detail.amount_saco > parseFloat(this.detail.product.stock)){
            Swal.fire("Alerta", "No hay stock sufiente el producto '"+this.detail.product.name+"' cuenta con una cantidad de "+this.detail.product.stock+" "+this.detail.product.um, "warning");
            return
          } else if((parseFloat(this.detail.product.stock) - this.detail.amount_saco < this.detail.product.minimum_quantity)){
            message = ", el producto '"+this.detail.product.name+"' se está agotando";
          }
        }

        Swal.fire("Alerta", "Agregado Correctamente"+message, "success");
        this.$emit("close-modal-detail");
        this.$emit("get-detail", this.detail);

        this.loadingDetail = false;

      },
      closeModalDetail(){
        this.$emit("close-modal-detail");
      },
      closeModalProducts() {
        this.flagModalProducts = false;
      },
      updateFromEquivalent() {

        const equivalent = parseFloat(this.detail.equivalent);
        const amount_saco = parseFloat(this.detail.amount_saco);

        if (!isNaN(equivalent) && equivalent > 0 && !isNaN(amount_saco) && amount_saco > 0) {
          this.detail.amount_kg = equivalent * amount_saco;
        } else {
          this.detail.amount_kg = 0;
        }

      },
      updateFromKg() {

        const equivalent = parseFloat(this.detail.equivalent);
        const amount_kg = parseFloat(this.detail.amount_kg);

        if (!isNaN(equivalent) && equivalent > 0 && !isNaN(amount_kg) && amount_kg > 0) {
          this.detail.amount_saco = amount_kg / equivalent;
        } else {
          this.detail.amount_saco = 0;
        }

      },
      updateFromSaco() {

        const equivalent = parseFloat(this.detail.equivalent);
        const amount_saco = parseFloat(this.detail.amount_saco);

        if (!isNaN(equivalent) && equivalent > 0 && !isNaN(amount_saco) && amount_saco > 0) {
          this.detail.amount_kg = equivalent * amount_saco;
        } else {
          this.detail.amount_kg = 0;
        }

      },
      async selectProduct(product){
        this.flagModalProducts                = false;
        this.detail.product.id                = product.id;
        this.detail.product.name              = product.name;
        this.detail.product.code              = product.cod_product;
        this.detail.product.process           = product.process;
        this.detail.product.id_unit_measure   = product.id_unit_measure;
        this.detail.product.um                = product.unit_measure;
        this.detail.product.slug              = product.unit_measure_data.slug ?? "kg";
        this.detail.product.stock             = product.stock;
        this.detail.product.minimum_quantity  = product.minimum_quantity;
        this.detail.product.converted_price   = product.converted_price;
        this.detail.equivalent                = product.equivalent;
        this.detail.price                     = product.price;
      },
      cleanModal(){
        this.detail.product.id                = "";
        this.detail.product.name              = "";
        this.detail.product.code              = "";
        this.detail.product.process           = "";
        this.detail.product.id_unit_measure   = 0;
        this.detail.product.slug              = "";
        this.detail.product.um                = "";
        this.detail.product.stock             = "";
        this.detail.product.minimum_quantity  = "";
        this.detail.product.converted_price   = "";
        this.detail.product.equivalent        = "";
        this.detail.price                     = "";
        this.detail.equivalent                = "";
        this.detail.amount                    = "";
        this.detail.amount_kg                 = "";
        this.detail.amount_saco               = "";
      },
      preventInvalidDecimal(event) {
        const key = event.key;
        const value = event.target.value;
        const selectionStart = event.target.selectionStart;
        const selectionEnd = event.target.selectionEnd;

        // Permitir sobrescribir el contenido seleccionado sin bloquear por largo de la cadena
        const isReplacing = selectionStart !== selectionEnd;

        // Permite solo números, un solo punto decimal, y teclas útiles como Retroceso, Suprimir, etc.
        if (!/^[0-9]$/.test(key) && key !== '.' && !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(key)) {
          event.preventDefault();
          return;
        }

        // Permitir borrar (Backspace, Delete) y escribir nuevamente en la parte entera
        if (['Backspace', 'Delete'].includes(key)) {
          return; // Permite borrar sin restricciones
        }

        // Asegura que solo se permita un punto decimal
        if (key === '.' && value.includes('.')) {
          event.preventDefault();
          return;
        }

        // Si estamos reemplazando texto, permite que se complete la sobrescritura
        if (isReplacing) {
          return;
        }

        // Limitar la parte entera a 8 dígitos si ya hay un punto decimal
        const [integerPart, decimalPart] = value.split('.');

        // Si no hay parte entera, permite seguir escribiendo (por si se borró todo)
        if (!integerPart && key !== '.') {
          return;
        }

        // Limitar la parte entera a 8 dígitos si ya hay un punto decimal o aún no se ha ingresado
        if (integerPart && integerPart.length >= 8 && key !== '.' && !value.includes('.')) {
          event.preventDefault();
          return;
        }

        // Limitar la parte decimal a 4 dígitos
        if (decimalPart && decimalPart.length >= 4 && value.includes('.')) {
          event.preventDefault();
        }
      },
    },
  };

</script>